import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="Privacy Policy" />

    <h1>Privacy Policy</h1>

    <p>Effective date from: September 12, 2019</p>
    <p>Last update: September 13, 2019</p>

    <p>
      JSON Shuffle ("us", "we", or "our") operates the{" "}
      <i>json-shuffle.online</i> website (the "Service").
    </p>

    <p>
      This page informs you of our policies regarding the collection, use, and
      disclosure of personal data when you use our Service and the choices you
      have associated with that data.
    </p>

    <p>
      We use your data to provide and improve the Service. By using the Service,
      you agree to the collection and use of information in accordance with this
      policy. Unless otherwise defined in this Privacy Policy, terms used in
      this Privacy Policy have the same meanings as in our Terms and Conditions,
      accessible from <i>json-shuffle.online</i>
    </p>

    <h3>Information Collection And Use</h3>

    <p>
      We do not collect your personal information, but we use Cookies to deliver
      and improve our Service to you.
    </p>

    <h4>Tracking & Cookies Data</h4>
    <p>
      Cookies are strictly used saving your preferences. We does not track
      Sensitive Information or single users. Additionally, JSON Shuffle uses
      third party add-ons including, but not limited to Google Analytics, Carbon
      Ads. They use cookies to identify you, save your preferences and serve
      relevant ads.
    </p>
    <p>
      Cookies are files with small amount of data which may include an anonymous
      unique identifier. Cookies are sent to your browser from a website and
      stored on your device. Tracking technologies also used are beacons, tags,
      and scripts to collect and track information and to improve and analyze
      our Service.
    </p>
    <p>
      You can instruct your browser to refuse all cookies or to indicate when a
      cookie is being sent. However, if you do not accept cookies, you may not
      be able to use some portions of our Service.
    </p>

    <h3>Use of Data</h3>

    <p>JSON Shuffle uses the collected data for various purposes:</p>
    <ul>
      <li>To provide and maintain the Service</li>
      <li>
        To allow you to participate in interactive features of our Service when
        you choose to do so
      </li>
      <li>To provide customer care and support</li>
      <li>
        To provide analysis or valuable information so that we can improve the
        Service
      </li>
      <li>To monitor the usage of the Service</li>
      <li>To detect, prevent and address technical issues</li>
    </ul>

    <h3>Transfer Of Data</h3>
    <p>
      Your information, including Personal Data, may be transferred to — and
      maintained on — computers located outside of your state, province, country
      or other governmental jurisdiction where the data protection laws may
      differ than those from your jurisdiction.
    </p>
    <p>
      Your consent to this Privacy Policy followed by your submission of such
      information represents your agreement to that transfer.
    </p>
    <p>
      JSON Shuffle will take all steps reasonably necessary to ensure that your
      data is treated securely and in accordance with this Privacy Policy and no
      transfer of your data will take place to an organization or a country
      unless there are adequate controls in place including the security of your
      data and other personal information.
    </p>

    <h3>Security Of Data</h3>
    <p>
      The security of your data is important to us, but remember that no method
      of transmission over the Internet, or method of electronic storage is 100%
      secure. While we strive to use commercially acceptable means to protect
      your Personal Data, we cannot guarantee its absolute security.
    </p>

    <h3>Service Providers</h3>
    <p>
      We may employ third party companies and individuals to facilitate our
      Service ("Service Providers"), to provide the Service on our behalf, to
      perform Service-related services or to assist us in analyzing how our
      Service is used.
    </p>
    <p>
      These third parties have access to your data only to perform these tasks
      on our behalf and are obligated not to disclose or use it for any other
      purpose.
    </p>

    <h3>Analytics</h3>
    <p>
      We may use third-party Service Providers to monitor and analyze the use of
      our Service.
    </p>
    <ul>
      <li>
        <p>
          <strong>Google Analytics</strong>
        </p>
        <p>
          Google Analytics is a web traffic analysis tool. You can read the
          Privacy Policy for Google Analytics here:
          <a href="https://support.google.com/analytics/answer/2838718">
            https://support.google.com/analytics/answer/2838718
          </a>
        </p>
      </li>
    </ul>

    <h3>Links To Other Sites</h3>
    <p>
      Our Service may contain links to other sites that are not operated by us.
      If you click on a third party link, you will be directed to that third
      party's site. We strongly advise you to review the Privacy Policy of every
      site you visit.
    </p>
    <p>
      We have no control over and assume no responsibility for the content,
      privacy policies or practices of any third party sites or services.
    </p>

    <h3>Children's Privacy</h3>
    <p>Our Service does not address anyone under the age of 18 ("Children").</p>
    <p>
      We do not knowingly collect personally identifiable information from
      anyone under the age of 18. If you are a parent or guardian and you are
      aware that your Children has provided us with Personal Data, please
      contact us. If we become aware that we have collected Personal Data from
      children without verification of parental consent, we take steps to remove
      that information from our servers.
    </p>

    <h3>Changes To This Privacy Policy</h3>
    <p>
      We may update our Privacy Policy from time to time. We will notify you of
      any changes by posting the new Privacy Policy on this page.
    </p>
    <p>
      We will let you know via email and/or a prominent notice on our Service,
      prior to the change becoming effective and update the "effective date" at
      the top of this Privacy Policy.
    </p>
    <p>
      You are advised to review this Privacy Policy periodically for any
      changes. Changes to this Privacy Policy are effective when they are posted
      on this page.
    </p>

    <h3>Contact Us</h3>
    <p>
      If you have any questions about this Privacy Policy, please contact us:
    </p>
    <ul>
      <li>By email: json.shuffle.app@gmail.com</li>
    </ul>
  </Layout>
)

export default SecondPage
